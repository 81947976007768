@tailwind base;
@tailwind components;
@tailwind utilities;

.theme-christianson {
	--color-primary: 79 70 229; /* indigo-600 */
	--color-secondary: 224 231 255; /* indigo-100 */
	--color-success: 22 163 74; /* green-600 */
	--color-error: 220 38 38; /* red-600 */
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
